import React from 'react';
import {Link} from 'gatsby';
import {FooterMenuWrap, FooterMenuList} from './footer-menu.stc'

const FooterMenu = () => {
    return (
        <FooterMenuWrap>
            <FooterMenuList>
                <Link to="/" className="no-cursor">ANASAYFA</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/kurumsal" className="no-cursor">KURUMSAL</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/projeler" className="no-cursor">PROJELER</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/blog" className="no-cursor">BLOG</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/iletisim" className="no-cursor">İLETİŞİM</Link>
            </FooterMenuList>
            <FooterMenuList>
                <Link to="/sitemap.xml" className="no-cursor">SİTEMAP</Link>
            </FooterMenuList>
        </FooterMenuWrap>
    )
}

export default FooterMenu;