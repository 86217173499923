import React from "react"
import wp from "../data/images/iletisim.svg"
import styled from "styled-components"

const WpWrapper = styled.div`
.whats-app {
  position: fixed;
  width: 58px;
  height: 58px;
  bottom: 25px;
  text-align: center;
  right: 20px;
  z-index: 100;
}
.my-float {
  margin-top: 10px;
}
`

const Whatsapp = () => (
    <WpWrapper>
        <a
            className="whats-app"
            href="https://wa.me/905536535553?text=Merhaba%20"
            rel="noopener noreferrer"
            target="_blank"
        >
            <div className="blob green">
                <img
                    className="fa fa-whatsapp my-float"
                    title="Konya Mimar Whatsapp"
                    src={wp}
                    alt="Konya Mimarlık Ofisi"
                />
            </div>
        </a>
    </WpWrapper>
)

export default Whatsapp