import React from 'react'
import { Link } from 'gatsby'
import { LogoWrapper } from './logo.stc';
import logo from '../../../assets/img/logo/logo.png';
import logoBlack from '../../../assets/img/logo/logo-black.png';

const MainLogo = (props) => {
    return (
        <LogoWrapper {...props}>
            <Link to="/" className="big-cursor">
                <img src={logo} alt="Konya Mimar Logo" className="white-logo" />
                <img src={logoBlack} alt="Konya Mimar Logo" className="black-logo" />
            </Link>
        </LogoWrapper>
    )
}

export default MainLogo
